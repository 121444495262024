export const defaultConfig = {
  maintenance: {
    show: undefined, // true|false causes redirect issues
    title: 'Prezados clientes',
    text: 'Informamos que nosso site está indisponível devido a trabalhos de manutenção programada. Durante esse período realizaremos uma atualização em nossa plataforma. Pedimos desculpas pelo inconveniente e agradecemos sua compreensão. Em breve estaremos de volta com todos os nossos serviços habituais.',
    button: {
      show: false,
      label: '',
      url: '',
    },
    chat: {
      show: true,
      label: 'Falar com especialista',
    },
  },
}