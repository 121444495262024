import styled from "styled-components";

import { colors } from "styles";
import LogoPorto from "assets/images/logos/logo-porto-servico.png";

export const Logo = styled.img.attrs({
  src: LogoPorto,
})`
  width: 270px;
  height: auto;
  margin: 0 auto 50px;
  display: block;
`;

export const Progress = styled.progress`
  appearance: none;
  border: none;
  width: calc(100% - 80px);
  max-width: 360px;
  height: 6px;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  
  ::-webkit-progress-bar {
    border-radius: 3px;
    background-color: ${colors.primary_light};
  };

  ::-webkit-progress-value {
    border-radius: 3px;
    background-color: ${colors.primary};
  };

  ::-moz-progress-bar {
    border-radius: 3px;
    background-color: ${colors.primary_light};
    color: white;
  };
`;