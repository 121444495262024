import React from "react";
import InputMask from "react-input-mask";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { colors } from "styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 30,

    "& label": {
      fontWeight: "300",
    },

    "& label.Mui-focused": {
      color: colors.primary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.primary,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.primary,
      },
    },
  },
}));

function Input(props) {
  const classes = useStyles();

  return (
    <InputMask
      mask={props.mask}
      maskChar=""
      onChange={props.onChange}
      disabled={props.disabled}
    >
      {() => (
        <TextField
          className={classes.root}
          id={props.id}
          label={props.label}
          {...props}
        />
      )}
    </InputMask>
  );
}

export default Input;
