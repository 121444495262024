import styled from "styled-components";

import LogoPorto from "assets/images/logos/logo-porto-servico.png";

export const Logo = styled.img.attrs({
  src: LogoPorto,
})`
  width: 270px;
  height: auto;
  margin: 0 auto 50px;
  display: block;
`;

export const H1 = styled.h1`
  margin-block-end: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3C6197;
`;

export const Paragraph = styled.p`
  margin-block: 1rem;
  text-align: center;
  hyphens: auto;

  &:last-child {
    margin-block-end: 0;
  }
`

export const Link = styled.a`
  display: block;
  margin-block-start: 1rem;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`