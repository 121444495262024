import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Loading(props) {
  return(
    <SkeletonTheme color="#f0f0f0" highlightColor="white">
      <Skeleton {...props} />
    </SkeletonTheme>
  )
}

export default Loading;