import styled from "styled-components";
import { rem } from "polished";

import { fonts, colors } from "styles";

export const Text = styled.p`
  margin-bottom: 33px;

  font-size: ${rem("18px")};
  text-align: center;
`;

export const Legend = styled.p`
  margin-bottom: 15px;

  text-align: center;
  font-size: ${rem("13px")};
  font-weight: ${fonts.light};
`;

export const Chat = styled.div`
  margin: 1rem 0 0 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: currentColor;

    :hover {
      color: ${colors.primary};
      cursor: pointer;
    }
  }
`;