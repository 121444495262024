import { createEvent } from "effector";

import store from "./store";

export const setOrderData = createEvent();

store.on(setOrderData, (state, payload) => ({
  ...state,
  ...state.values,
  ...payload,
}));
