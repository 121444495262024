import React, { useEffect, useState } from "react";

import { useAuthentication } from "modules/authentication";
import { analyticsEvent } from "modules/analytics";
import { chatEvent } from "modules/chat";
import { getScheduling, doSchedule } from "services/api";

import toast from "react-hot-toast";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Typography, Button, Loading } from "components";

import Calendar from "./components/Calendar";
import Hours from "./components/Hours";

import * as S from "./styles";

function Scheduling(props) {
  
  const { store, setOrderData } = useAuthentication();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [day, setDay] = useState(false);
  const [initialDay, setInitialDay] = useState(false);
  const [endDay, setEndDay] = useState(false);
  const [hour, setHour] = useState(false);
  const [dateTime, setDateTime] = useState(false);
  const [data, setData] = useState({
    calendar: [],
  });
  const [noData, setNoData] = useState(true);

  const copy = {};
  switch(store.type) {

    case "reschedule":
      copy.title = "Reagendamento:";
      copy.subtitle = "Validado com sucesso!";
      copy.description = "";
      copy.analytics = "Reagendamento";
      break;

    case "pieces":
      copy.title = "Retorno:";
      copy.subtitle = "Serviço de peças";
      copy.description = "Aprovado para agendamento de reposição ou reparo de peça.";
      copy.analytics = "Retorno - Peças";
      break;

    case "guarantee":
      copy.title = "Retorno:";
      copy.subtitle = "Garantia de serviço";
      copy.description = "Aprovado para agendamento de Garantia de serviço.";
      copy.analytics = "Retorno - Garantia";
      break;

    default:
      break;
  }

  async function getCalendar() {

    try {

      setLoading(true);

      const response = await getScheduling(store.uuid);

      if(response.status !== 200 || !response.data.calendar) throw response;

      setData(response.data);
      setNoData(false);

      const days = Object.keys(response.data.calendar);
      setInitialDay(days[0]);
      setEndDay(days.slice(-1)[0]);

    } catch (error) {

      //console.warn("scheduling:error", error);
      let message = "";

      if(error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if(error?.response?.data?.type === "@data/not-found") {
        message = "Sua sessão expirou.";
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      // if(error.response.data.type === "@databse/not-found") {
      //   message = "Agenda indisponível.";
      // }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    getCalendar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDateClick(arg) {

    const dayFormat = dayjs(arg).locale("pt-br").format("YYYY-MM-DD");
    const daySelected = data.calendar[dayFormat];
    setDay(daySelected);

    analyticsEvent({
      action: 'click',
      category: 'calendar',
      label: 'date'
    });
  }

  function handleHour(arg) {

    setHour(arg);
    // setDateTime(`${arg.date} ${arg.hour}:00`);
    setDateTime({
      date: arg.date,
      time: arg.range.start,
      range: {
        start: arg.range.start,
        finish: arg.range.finish
      }
    });
    setOrderData({
      datetime: `${dateTime.date} ${dateTime.time}:00`,
      ...dateTime
    });

    analyticsEvent({
      action: 'click',
      category: 'calendar',
      label: 'time'
    });
  }

  function handleRefresh() {

    analyticsEvent({
      action: 'click',
      category: 'button',
      label: 'Atualizar'
    });

    getCalendar();

  }

  function handleChat() {

    chatEvent({
      uuid: store.uuid,
      origin: copy.analytics || 'Agenda'
    });

  }

  async function handleSubmit() {

    const payload = {
      uuid: store.uuid,
      type: store.type,
      datetime: `${dateTime.date} ${dateTime.time}:00`,
      
      date: dateTime.date,
      time: dateTime.range.start,
      range: {
        start: dateTime.range.start,
        finish: dateTime.range.finish
      }
    }

    try {

      analyticsEvent({
        action: 'click',
        category: 'button',
        label: 'Confirmar'
      });

      setProcessing(true);

      const response = await doSchedule(payload);
      
      if(response.status !== 200 || !response.data.numero_os) throw response;

      setOrderData({
        novo_numero_os: (response.data.numero_os !== store.numero_os) ? response.data.numero_os : ""
      });

      props.push('feedback');

    } catch (error) {

      //console.warn("schedule:error", {error});
      let message = "Ocorreu um erro. Tente novamente.";

      if(error?.response?.data?.message) {
        message = error.response.data.message;
      }

      // if(error.data.err) {
      //   message = error.data?.err?.message || message;
      // }

      // if(error?.response?.data?.type === "@integration/fail") {
      //   message = error.response.data?.message || message;
      // }

      // if(error?.response?.data?.type === "@pfaz/get-scheduling") {
      //   message = error.response.data?.message || message;
      // }

      if(error?.response?.data?.type === "@data/not-found") {
        message = "Sua sessão expirou.";
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

      setProcessing(false);

    } finally {
      // setProcessing(false);
    }

  }

  return (
    <>
      <Typography.H1>
        {/*loading ? <Loading width="100%" height="32px"/> : copy?.title*/}
        {copy?.title}
      </Typography.H1>

      <Typography.H2>
        {/*loading ? <Loading width="100%" height="32px"/> : copy?.subtitle*/}
        {copy?.subtitle}
      </Typography.H2>

      <S.Text>
        {/*loading ? "" : copy?.description*/}
        {copy?.description}
      </S.Text>

      <S.Legend>
        {/*loading ? "" : "Escolha uma data"*/}
        Escolha uma data
      </S.Legend>

      {loading ? (
        <Loading width="100%" height="250px" style={{marginBottom: 25}}/>
      ) : (
        <Calendar
          minDate={new Date(dayjs(initialDay).format())}
          maxDate={new Date(dayjs(endDay).format())}
          onClickDay={handleDateClick}
        />
      )}
      {day && (
        <>
          <S.Legend>Escolha um horário</S.Legend>
          <Hours days={day} hour={hour} handleHour={handleHour} />
        </>
      )}

      {noData ? 

        <>
          <Button
            disabled={loading}
            onClick={handleRefresh}
            fill="outline"
          >
            Atualizar
          </Button>
          <S.Chat>Agenda indisponível? <a href onClick={handleChat}>Clique aqui</a> e fale com o nosso chat.</S.Chat>
        </> :

        <Button
          disabled={!day || !hour}
          isLoading={processing}
          onClick={handleSubmit}
        >
          Confirmar
        </Button>
        
      }
    </>
  );
}

export default Scheduling;
