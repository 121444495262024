import React from "react";
import { useAuthentication } from "modules/authentication";
import { chatEvent } from "modules/chat";
import { Button } from "components";

//import * as S from "./styles";

function Chat() {

  const {store} = useAuthentication();

  function handleChat() {

    chatEvent({
      uuid: store.uuid,
      origin: 'Login (celular não encontrado)'
    });

  }

  return (
    <>
      <Button
        onClick={handleChat}
      >
        Chat online
      </Button>
    </>
  );
}

export default Chat;
