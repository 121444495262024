import React from "react";

import Calendary from "react-calendar";

import "react-calendar/dist/Calendar.css";

import * as S from "./styles";

function Calendar(props) {
  return (
    <S.Container>
      <Calendary
        {...props}
        locale="pt-BR"
        selectRange={false}
      />
    </S.Container>
  );
}

export default Calendar;
