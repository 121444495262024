import styled from "styled-components";

import { colors, fonts } from "styles";

export const Center = styled.div`
  margin: 100px 0;

  .styles_react-code-input-container__tpiKG {
    width: 100% !important;
  }

  .styles_react-code-input__CRulA {
    display: flex;
    justify-content: space-between;
  }

  .styles_react-code-input__CRulA > input {
    border: none;
    border-bottom: solid 1px ${colors.black};
    font-family: "Open Sans", sans-serif;
    font-weight: ${fonts.light};
    color: ${colors.black};
    text-transform: uppercase;

    :focus {
      border-color: ${colors.primary};
      caret-color: ${colors.primary};
      border-bottom: solid 1px ${colors.black};
    }

    :first-of-type {
      border-radius: 0;
    }

    :last-of-type {
      border-radius: 0;
      border: none;
      border-bottom: solid 1px #000;
    }
  }
`;

export const Chat = styled.div`
  margin: 1rem 0 0 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: currentColor;

    :hover {
      color: ${colors.primary};
      cursor: pointer;
    }
  }
`