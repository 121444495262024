import styled from "styled-components";

export const Form = styled.form`
  margin: 0 25px;
`;

export const ErrorMessage = styled.span`
  a {
    color: inherit;
  }
`