import Views from "views";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    public: true,
    path: "/manutencao",
    exact: true,
    component: Views.Maintenance,
  },
  {
    public: true,
    path: "/",
    exact: true,
    component: Views.Wizard,
  },
];
