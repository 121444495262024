import React, { useEffect } from "react";

import { useAuthentication } from "modules/authentication";
import { analyticsEvent } from "modules/analytics";

import { Typography, Button } from "components";

import * as S from "./styles";

function Feedback(props) {

  const { store } = useAuthentication();
  const type = store.type;

  function handleRestart() {

    analyticsEvent({
      action: 'click',
      category: 'button',
      label: 'Fazer novo pedido'
    });

    //props.push('auth');
    document.location = process.env.REACT_APP_BASE_URL;
  }

  useEffect(() => {

    analyticsEvent({
      action: (type === 'cancel') ? 'cancel' : 'schedule',
      category: 'engagement',
      label: (store.novo_numero_os) ? `${store.numero_os}:${store.novo_numero_os}` : store.numero_os
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.H1>
        {(type === 'cancel') ? 'Cancelamento' : 'Agendamento'} realizado com sucesso!
      </Typography.H1>
      <Typography.H2>
        Enviamos um SMS com os detalhes para você.
      </Typography.H2>
      {store.novo_numero_os && 
        <>
          <S.Legend>Sua sua nova Ordem de Serviço (O.S.):</S.Legend>
          <S.Text>{store.novo_numero_os}</S.Text>
        </>
      }
      <Button
        onClick={handleRestart}
      >
        Fazer novo pedido
      </Button>
    </>
  );
}

export default Feedback;
