import React from "react";
import { Toaster } from "react-hot-toast";
import Router from "routes/router";
import { global as GlobalStyles } from "styles";

import { useAnalytics } from "modules/analytics";

function App() {

  useAnalytics({
    id: process.env.REACT_APP_ANALYTICS_ID
  });

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <GlobalStyles />
      <Router />
    </>
  );
}

export default App;
