import React from "react";

import * as S from "./styles";

function Hours({ days, hour, handleHour }) {
  return (
    <S.Container>
      {days.map((day, index) => (
        <S.Item
          key={index}
          onClick={() => handleHour(day)}
          active={day.range?.start === hour?.range?.start}
        >
          <S.Hour>
            {day.range.start} - {day.range.finish}
          </S.Hour>
        </S.Item>
      ))}
    </S.Container>
  );
}

export default Hours;
