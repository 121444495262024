import { useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { History } from "history";

class BrowserHistory {
  /**
   * @type {History<{}>}
   */
  currentHistory = null;

  setCurrentHistory(newHistory) {
    this.currentHistory = newHistory;
  }
}

const history = new BrowserHistory();

export function BrowserHistoryConsumer() {
  const historyDom = useHistory();
  if (!history.currentHistory) history.setCurrentHistory(historyDom);
  return null;
}

export default history;
