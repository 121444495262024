import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Recaptcha from "react-google-invisible-recaptcha";

import { AUTO_SERVICO_TOKEN_NAME, getToken } from "services/api";

import { useAuthentication } from "modules/authentication";
import { analyticsEvent } from "modules/analytics";

import validationSchema from "./validation";

import { Forms, Button } from "components";
import * as S from "./styles";

function Auth(props) {
  const { setOrderData } = useAuthentication();

  const [loading, setLoading] = useState(false);
  const [docMask, setDocMask] = useState("");
  const [authInfo, setAuthInfo] = useState(null);

  const [captcha, setCaptcha] = useState(null);
  const recaptcha = useRef(null);

  function onRecaptchaResolved(recaptchaToken) {
    setCaptcha(recaptchaToken);
  }

  useEffect(() => {
    if (captcha) {
      onSubmit(captcha);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captcha]);

  async function onSubmit(capt) {
    try {
      const values = { ...authInfo };

      analyticsEvent({
        action: "click",
        category: "button",
        label: "Validar",
      });

      setLoading(true);

      setOrderData({ ...values });

      const response = await getToken(values, capt);

      if (response.status !== 200 || !response.data.uuid) throw response;

      const authToken = response.data?.token;

      localStorage.setItem(AUTO_SERVICO_TOKEN_NAME, authToken);

      setOrderData({ ...response.data });

      const cellphone = response?.data?.cellphone
        ? ` para ${response.data.cellphone}`
        : "";

      toast.success(`Token enviado${cellphone}.`, {
        duration: 8000,
      });

      // next step
      props.push("validation");
    } catch (error) {
      //console.warn('auth:error', {error});
      let message = "Ocorreu um erro.";

      if (error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if (error?.response?.data?.type === "@orders/cellphone") {
        props.push("chat");
      }

      toast.error(
        <S.ErrorMessage>
          {message} Tente novamente ou entre em contato com um de nossos
          atendentes através do{" "}
          <a
            href={process.env.REACT_APP_CHAT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            chat
          </a>
          .
        </S.ErrorMessage>,
        {
          duration: 8000,
        }
      );

      analyticsEvent({
        action: "exception",
        description: message,
        fatal: false,
      });

      setLoading(false);
    } finally {
      // setLoading(false);
    }
  }

  const form = useFormik({
    initialValues: {
      numero_os: "",
      cpf: "",
    },

    validationSchema,

    onSubmit: async (values) => {
      setAuthInfo({ ...values });
      recaptcha.current.execute();
    },
  });

  return (
    <>
      <Recaptcha
        ref={recaptcha}
        sitekey={process.env.REACT_APP_RECAPTCHA}
        onResolved={onRecaptchaResolved}
        locale="pt-br"
        style={{ display: "none", visibility: "hidden" }}
      />
      <S.Form onSubmit={form.handleSubmit} autoComplete="off">
        <Forms.Input
          id="numero_os"
          label="Número do Serviço (OS)"
          name="numero_os"
          type="tel"
          mask="9999999-99"
          onChange={form.handleChange}
          fullWidth
          disabled={loading}
          error={form.touched.numero_os && form.errors.numero_os}
          helperText={form.touched.numero_os && form.errors.numero_os}
        />
        {form.values.cpf.length <= 14 ? (
          <Forms.Input
            id="cpf"
            label="CPF ou CNPJ"
            name="cpf"
            type="tel"
            mask={"999.999.999-999"}
            onChange={(e) => form.handleChange(e)}
            fullWidth
            disabled={loading}
            error={form.touched.cpf && form.errors.cpf}
            helperText={form.touched.cpf && form.errors.cpf}
          />
        ) : (
          <Forms.Input
            id="cpf"
            label="CPF ou CNPJ"
            name="cpf"
            type="tel"
            mask={"99.999.999/9999-99"}
            onChange={(e) => form.handleChange(e)}
            fullWidth
            disabled={loading}
            error={form.touched.cpf && form.errors.cpf}
            helperText={form.touched.cpf && form.errors.cpf}
          />
        )}

        <Button type="submit" mt="25px" isLoading={loading}>
          Validar
        </Button>
      </S.Form>
    </>
  );
}

export default Auth;
