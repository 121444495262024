import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import firebase from 'services/firebase';
import 'firebase/remote-config';

import { defaultConfig } from './defaultConfig';

// setup remote config
const remoteConfig = firebase.remoteConfig();
const fetchInterval = 1000 * 60 * 10; // 10 minutes
remoteConfig.settings.minimumFetchIntervalMillis = fetchInterval - 1000;

// context
export const RemoteConfigContext = createContext(defaultConfig);

const RemoteConfigProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [config, setConfig] = useState(defaultConfig);

  // fetch config
  useEffect(() => {
    const fetchConfig = async () => {
      await remoteConfig.fetchAndActivate();
      const response = remoteConfig.getString('config');
      const newConfig = JSON.parse(response);

      setConfig(currentConfig => {
        const hasChanged = !areEqual(currentConfig, newConfig);
    
        return hasChanged ? newConfig : currentConfig;
      });
    };

    // fetch immediately
    fetchConfig();

    // fetch interval
    const reFetchConfig = setInterval(fetchConfig, fetchInterval);

    // clear fetch interval (component unmount)
    return () => clearInterval(reFetchConfig);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // handle config
  useEffect(() => {
    const inMaintenance = config?.maintenance?.show;
  
    if (inMaintenance === true) {
      if(location.pathname !== '/manutencao') history.push('/manutencao');
    } else if (inMaintenance === false) {
      if(location.pathname === '/manutencao') history.push('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <RemoteConfigContext.Provider value={config}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

// deep comparison between objects
function areEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !areEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

export default RemoteConfigProvider;