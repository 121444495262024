import Auth from "./Auth";
import Chat from "./Chat";
import Validation from "./Validation";
import Options from "./Options";
// import Return from "./Return";
// import Rescheduling from "./Rescheduling";
import Scheduling from "./Scheduling";
import Feedback from "./Feedback";

export const steps = [
  {
    id: "auth",
    analytics_id: "login",
    title: "Vamos começar?",
    subTitle:
      "Para reagendar, cancelar ou solicitar retorno, precisamos validar alguns dados.",
    logo: true,
    progressBar: false,
    render: Auth,
  }, {
    id: "chat",
    analytics_id: "chat",
    title: "Celular não localizado para envio de token.",
    subTitle:
      "Por favor, procure o atendimento via chat online.",
    logo: true,
    progressBar: false,
    render: Chat,
  }, {
    id: "validation",
    analytics_id: "login-password",
    title: "Validação feita com sucesso.",
    subTitle: " Agora, informe o código que enviamos por SMS:",
    logo: false,
    progressBar: true,
    render: Validation,
  }, {
    id: "options",
    analytics_id: "login-success",
    title: "Código validado com sucesso!",
    // subTitle: " Agora, escolha uma das opções",
    logo: false,
    progressBar: true,
    render: Options,
  }, /*{
    id: "return",
    analytics_id: "action-return",
    title: "Certo. Sobre o retorno:",
    subTitle: " Você precisa de qual tipo?",
    logo: false,
    progressBar: true,
    render: Return,
  }, {
    id: "rescheduling",
    analytics_id: "action-rescheduling",
    title: "Certo. Sobre o reagendamento:",
    logo: false,
    progressBar: true,
    render: Rescheduling,
  },*/ {
    id: "scheduling",
    analytics_id: "schedule",
    logo: false,
    progressBar: true,
    render: Scheduling,
  }, {
    id: "feedback",
    analytics_id: "schedule-success",
    logo: false,
    progressBar: false,
    render: Feedback,
  },
];
