import { useEffect } from "react";

export const useAnalytics = (params) => {

  useEffect(() => {

    try {

      const {id} = params;

      if(!id) throw new Error('Missing Analytics ID');

      // data layer
      window.dataLayer = window.dataLayer || [];
      analyticsPush('js', new Date());
      analyticsPush('config', id, {
        send_page_view: false
      });

      // script
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }

    } catch(error) {
      console.warn(error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

};

export function analyticsPush() {

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);

}

export function analyticsEvent(params) {

  const { action } = params;

  switch(action) {

    case 'page_view':
      const { page } = params;
      if(!page) break;
      analyticsPush('event', action, {
        page_title: page || ''
      });
      break;
      
    case 'exception':
      const { description, fatal } = params;
      analyticsPush('event', action, {
        description: description || '',
        fatal: fatal || false
      });
      break;

    default:
      const { category, label, value } = params;
      analyticsPush('event', action, {
        event_category: category || '',
        event_label: label || '',
        value: value || 1
      });
      break;

  }
  
}