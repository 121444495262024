import styled from "styled-components";

import { colors } from "styles";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* width: 100%; */
  /* height: 100%; */

  background-color: ${colors.neutral};
`;
