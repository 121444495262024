import React, { useContext } from "react";

import { useHistory } from "react-router-dom";
import { RemoteConfigContext } from "providers/RemoteConfig";

import { Button, Card } from "components";
import * as S from "./styles";

function Maintenance(props) {
  const history = useHistory();
  const config = useContext(RemoteConfigContext);
  const { title, text, button, chat } = config?.maintenance || {};

  const handleButton = () => {
    history.push(button?.url);
  }

  const handleChat = () => {
    window.open(process.env.REACT_APP_CHAT_URL, "_blank");
  }

  return (
    <>
      <Card>
        <S.Logo />
        <S.H1>{title}</S.H1>
        <S.Paragraph>{text}</S.Paragraph>
        {button?.show && button.url && (
          <Button
            variant="primary"
            type="button"
            onClick={handleButton}
          >
            {button.label}
          </Button>
        )}

        {chat?.show && (
          <S.Link
            target="_blank"
            rel="noopener"
            onClick={handleChat}
          >
            {chat.label}
          </S.Link>
        )}
      </Card>
    </>
  );
}

export default Maintenance;
