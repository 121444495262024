import styled from "styled-components";
import { rgba } from "polished";

import { colors } from "styles";

export const Container = styled.div`
  margin-bottom: 25px;

  .react-calendar {
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: ${colors.primary};
    color: white;
  }

  .react-calendar__tile:disabled {
    background-color: ${colors.neutral};
  }

  .react-calendar__tile--now {
    background: ${colors.primary_light};
  }

  .react-calendar__tile--active {
    background: ${colors.primary} !important;
    color: ${colors.white} !important;
  }

  .react-calendar__navigation button[disabled],
  .react-calendar__month-view__weekdays {
    background: ${colors.neutral};
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
`;
