import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { LayoutBlank } from "layouts";

import routes from "./index";
import { BrowserHistoryConsumer } from "./history.js";
import RouteRenderer from "./Renderer";

import RemoteConfigProvider from "providers/RemoteConfig";

export default function Router() {
  return (
    <BrowserRouter>
      <BrowserHistoryConsumer />
      <RemoteConfigProvider>
        <Switch>
          {routes.map(
            ({
              layout: Layout = LayoutBlank,
              component: Component,
              ...route
            }) => (
              <Route
                exact
                key={route.path}
                {...route}
                render={(props) => (
                  <RouteRenderer
                    {...props}
                    route={route}
                    Layout={Layout}
                    Component={Component}
                  />
                )}
              />
            )
          )}
        </Switch>
      </RemoteConfigProvider>
    </BrowserRouter>
  );
}
