import styled from "styled-components";
import { rem } from "polished";

import { fonts, colors } from "styles";

export const Text = styled.p`
  margin-bottom: 33px;

  font-size: ${rem("31px")};
  font-weight: ${fonts.semibold};
  color: ${colors.black};
  text-align: center;
`;

export const Legend = styled.p`
  margin-bottom: 5px;

  text-align: center;
  font-size: ${rem("12px")};
  font-weight: ${fonts.light};
`;
