import React from "react";
import "./ChatButton.css";
import ChatIconSvg from "./ChatIcon.svg";

const ChatButton = () => {
  const handleClick = () => {
    window.open(
      "https://prime.altubots.com/chats/portoassistencia/e67490dac9a657589b41411700ce378c/index.html",
      "_blank"
    );
  };

  return (
    <button className="chat-button" onClick={handleClick}>
      <img className="chat-icon-svg" src={ChatIconSvg} alt="Chat" />
    </button>
  );
};

export default ChatButton;
