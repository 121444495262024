import { useStore } from "effector-react";

import store from "./store";
import * as actions from "./actions";

export function useAuthentication() {
  return {
    store: useStore(store),
    ...actions,
  };
}
