import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

import { getOrder, checkOrder, doCancel } from "services/api";
import { useAuthentication } from "modules/authentication";
import { analyticsEvent } from "modules/analytics";
import { chatEvent } from "modules/chat";

import { Typography, Button, Loading } from "components";

import * as S from "./styles";

function Options(props) {
  
  const { store, setOrderData } = useAuthentication();

  const [OSInfo, setOSInfo] = useState({
    error: "Dados não encontrados."
  });

  const [OSActions, setOSActions] = useState([]);
  const [hasOSActions, setHasOSActions] = useState(false);

  const [loadingData, setLoadingData] = useState(true);
  const [validatingActions, setValidatingActions] = useState(true);
  const [isCanceling, setIsCanceling] = useState(false);

  async function getOrderData() {

    try {

      setLoadingData(true);

      const response = await getOrder(store.uuid);

      if(response.status !== 200 || !response.data.client) throw response;

      setOSInfo({ ...response.data });
      setOrderData({ ...response.data });

    } catch (error) {

      let message = "";

      if(error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if(error?.response?.data?.type === "@data/not-found") {
        message = "Sua sessão expirou.";
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

    } finally {
      setLoadingData(false);
    }
  }

  async function checkOrderActions() {

    try {

      setValidatingActions(true);

      const response = await checkOrder(store.uuid);
      
      if(response.status !== 200 || !response.data) throw response;

      const actions = Object.entries(response.data).map(entry => {
        return {
          ...entry[1],
          type: entry[0]
        }
      });
      
      if(actions) {
        setOSActions(actions);
        setHasOSActions(
          actions.filter(action => action.enabled === true).length ? true : false
        )
      }

    } catch (error) {
      //console.warn(`checkOrderActions:error`, {error});
    } finally {
      setValidatingActions(false);
    }

  }

  async function handleAction({type, label, enabled, message}) {

    analyticsEvent({
      action: 'click',
      category: 'button',
      label: label
    });

    if(!enabled) {

      toast(<span dangerouslySetInnerHTML={{ __html: message }}/>, {
        duration: 8000,
      });

      return;
    }

    setOrderData({type});

    if(type === 'cancel') {

      await handleCancel();

    } else {
      
      props.push("scheduling");

    }
    
  }

  async function handleCancel() {

    try {

      setIsCanceling(true);

      const response = await doCancel({uuid: store.uuid});

      if(response.status !== 200 || !response.data.OS) throw response;

      setOrderData({
        novo_numero_os: "" // (response.data.OS !== store.numero_os) ? response.data.OS : ""
      });

      props.push("feedback");

    } catch (error) {

      let message = "Ocorreu um erro. Tente novamente.";

      if(error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if(error?.response?.data?.type === "@data/not-found") {
        message = "Sua sessão expirou.";
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false
      });

      setIsCanceling(false);

    } finally {
      // setIsCanceling(false);
    }

  }

  function handleChat() {

    chatEvent({
      uuid: store.uuid,
      origin: 'Options'
    });

  }

  useEffect(() => {

    getOrderData();
    checkOrderActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    analyticsEvent({
      action: 'login',
      category: 'engagement',
      label: store.numero_os
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      
      {/* <Typography.H2>{hasOSActions ?
      `Agora, siga com a sua opção:` : "Não há opções disponíveis nesse momento." }</Typography.H2> */}
      <Typography.H2>Agora, siga com a sua opção:</Typography.H2>

      {loadingData ? 
        <Loading width="100%" height="200px" /> : 
        <S.Infos>
          {OSInfo?.error ? 
            <p>{OSInfo.error}</p> :
            <>
              <p>{OSInfo?.client?.service}</p>
              <p>{OSInfo?.client?.name}</p>
              <p>{OSInfo?.client?.address?.street}, {OSInfo?.client?.address?.number}
              <br/>{OSInfo?.client?.address?.neighborhood}, {OSInfo?.client?.address?.city}, {OSInfo?.client?.address?.state}</p>
            </>
          }
        </S.Infos>
      }
      
      {validatingActions ?
        <>
          <Loading width="100%" height="50px" style={{ marginTop: 25 }} />
        </> : 
        <S.Actions>
          {OSActions.map((action, index) => {
            const isTypeCancel = (action.type === 'cancel');
            return (
              <Button 
                key={index}
                onClick={() => handleAction({
                  type: action.type,
                  label: action.label,
                  enabled: action.enabled,
                  message: action.message
                })}
                isLoading={isTypeCancel ? isCanceling : false}
                disabled={!isTypeCancel ? isCanceling : false}
              >
                {action.label}
              </Button>
              )
          })}
          <S.Chat>Isso resolve o seu problema? Se não, <a href onClick={handleChat}>clique aqui</a> e fale com o nosso chat.</S.Chat>
        </S.Actions>
        // <>
        //   {hasOSActions ? OSActions.map((action, index) => {
        //     if(action.enabled) {
        //       return (
        //         <Button key={index} onClick={() => handleAction({type: action.type, label: action.label})} >
        //           {action.label}
        //         </Button>
        //       )
        //     } else {
        //       return ""
        //     }
        //   }) : 
        //     <>
        //       {/* {!hasOSActions ? <S.Why>{OSActions.map(action => <li>{action.message}</li>)}</S.Why> : ''} */}
        //       <Button onClick={handleChat} >
        //         Chat online
        //       </Button>
        //     </>
        //   }

        //   {hasOSActions ? <S.Chat>Isso resolve o seu problema? Se não, <a href onClick={handleChat}>clique aqui</a> e fale com o nosso chat.</S.Chat> : ""}
        // </>
      }
    </>
  );
}

export default Options;
