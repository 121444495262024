import styled, { css } from "styled-components";

import { colors } from "styles";

export const Container = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 65px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    margin-left: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.15);
  }
`;

export const Hour = styled.time`
  position: relative;
  font-size: 16px;
  color: ${colors.black95};
`;

export const Item = styled.div`
  padding: 15px;

  cursor: pointer;

  :hover {
    background: ${colors.primary};

    ${({ Hour }) => css`
      color: #fff;
    `}
  }
  ${({ active }) =>
    active &&
    css`
      background: ${colors.primary};

      ${({ Hour }) => css`
        color: #fff;
      `}
    `}
`;
