import React from "react";
import { Redirect } from "react-router-dom";

export default function Renderer({ Layout, Component, ...props }) {
  if (!props.route.public)
    return (
      <Redirect
        to={{
          pathname: "/",
          state: {
            from: props.location,
          },
        }}
      />
    );

  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
}
