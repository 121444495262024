import styled from "styled-components";

import { colors } from "styles";

export const Container = styled.div`
  position: relative;

  padding: 50px 25px;
  max-width: 400px;

  border-radius: 15px;
  background-color: ${colors.white};
  box-shadow: 0 0 1rem -0.5rem black;
  z-index: 2;

  @media (min-width: 600px) {
    min-width: 400px;
    margin: 20px auto;
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 20px;
  }
`;
