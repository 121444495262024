import * as yup from "yup";

const validateCpfCnpj = (value) => {
  if (!value) return false;

  const replacedValue = value
    .replaceAll(".", "")
    .replace("/", "")
    .replace("-", "");

  const isCpf = replacedValue.length === 11;
  const isCnpj = replacedValue.length === 14;

  return isCpf || isCnpj;
};

export default yup.object({
  numero_os: yup
    .string()
    .matches(/^[0-9]{7}-[0-9]{2}$/, "OS inválida")
    .required("Campo obrigatório"),
  cpf: yup
    .string()
    .test("validate-cpf-cnpj", "CPF ou CNPJ inválido", validateCpfCnpj)
    .required("Campo obrigatório"),
});
