import styled from "styled-components";
import { rem } from "polished";

import { colors, fonts } from "styles";

export const Container = styled.h2`
  margin-bottom: 50px;

  text-align: center;
  font-size: ${rem("24px")};
  color: ${colors.black};
  font-weight: ${fonts.light};
`;
