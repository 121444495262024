import styled from "styled-components";

import { colors } from "styles";

export const Infos = styled.div`
  position: relative;

  /*padding: 22px;
  margin: 0 32px 50px;*/
  padding: 25px;
  margin: 0 0 50px;
  border-radius: 5px;
  text-align: center;

  background: ${colors.neutral};

  /*:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    background-color: ${colors.black};
  }*/

  p {
    /*:first-of-type {
      text-transform: uppercase;
    }*/
    text-transform: uppercase;

    :not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
`;

export const Chat = styled.div`
  margin: 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: currentColor;

    :hover {
      color: ${colors.primary};
      cursor: pointer;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`